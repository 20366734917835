import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import 'rxjs/add/operator/map';

@Injectable()
export class SimService{
    constructor (){}

    getSimActions(workAround): Observable<any> {
        let actionBar = [
            {
                "icon" : "nb-plus",
                "contextMenu" : [
                    { 
                        "title": 'Buy Devices',
                        "queryParams":
                            {"action": "addSim"}
                    }
                ],
                "menuTag" : workAround + "simPlus"
            },
            /*{
                "icon" : "nb-gear",
                "contextMenu" : [
                    { "title": 'Add Sims' },
                    { "title": 'Delete Sims' }
                ],
                "menuTag" : workAround + "gear",
            },*/
        ];
        let cardActions = [
            {
                "title" : "Confiugre",
                "queryParams": {
                    "action": "configureSim"
                }
            },{
                "title" : "Details",
                "queryParams": {
                    "action": "simDetails"
                }
            },{
                "title" : "Credit",
                "queryParams": {
                    "action": "simCredit"
                }
            }, {
                "title" : "Move Device",
                "queryParams": {
                    "action": "moveSim"
                }
            }, {
                "title" : "Device Actions",
                "queryParams" : {
                    "action": "simActions"
                }
            }, {
                "title" : "Usage Records",
                "queryParams": {
                    "action" : "simUsage",
                }
            }
        ];

        let actions = {
            "actionBar": actionBar,
            "cardActions": cardActions,
        }

        return Observable.of(actions);
    }
}