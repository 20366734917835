import { Component, Input, OnInit } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { UserService } from '../../../@core/data/users.service';
import { GroupService } from '../../../@core/data/groups.service';
import { OrderService } from '../../../@core/data/orders.service'
import { AnalyticsService } from '../../../@core/utils/analytics.service';

import { Subscription } from 'rxjs/Rx';
import { Router } from '@angular/router';

import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {


  @Input() position = 'normal';
  private orderSubscription: Subscription;
  private order: {};

  user: any;

  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private userService: UserService,
              private groupService: GroupService,
              private orderService: OrderService,
              private router: Router,
              private analyticsService: AnalyticsService,
              private authService: NbAuthService) {

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {

        if (token.isValid()) {
          //console.log(JSON.stringify(token));
          //console.log(JSON.stringify(token.getPayload()));
          this.user = token.getPayload();
          userService.setUser(this.user);
          this.groupService.setGroup(this.user.parent);

          this.orderService.getUserCreatedOrder(this.user);
        }

      });
    
  }

  ngOnInit(){
    this.orderSubscription = this.orderService.orderCreated$.subscribe(
      data => this.order = data
    );
  

  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');
    return false;
  }

  openCart(){    
    this.router.navigate(['/pages/cart/usercart']);
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }
}
