import { Injectable,Inject } from '@angular/core';
import { Observable ,of } from 'rxjs';

import { APP_CONFIG, AppConfig } from '../../app.config';
import { HttpClient } from '@angular/common/http';

// Required for the active group emitter
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class GroupService {
    private _activeGroup = new BehaviorSubject({});
    groupActivated$ = this._activeGroup.asObservable();

    private _ancestors = new BehaviorSubject([]);
    ancestorsUpdated$ = this._ancestors.asObservable();

    private group: any;
    private host: String;
    private ancestors: any[];

    constructor(@Inject(APP_CONFIG) config: AppConfig, private httpClient: HttpClient) {
        this.host = config.baseEndpoint;
    }

    // Return the currently selected Group
    getActiveGroup(): Observable<any> {
        return Observable.of(this.group);
    }

    activateGroup(group){
        this.group = group;
        this._activeGroup.next(group);
    }

    updateAncestors(group,groupId){
        if(!this.ancestors && group){
            this.ancestors=[group];
        } else if(group){
            // see if an item in the breadcrumb was clicked
            var index = this.ancestors.findIndex(x => x.uuid == groupId);
            if(index>0){
                this.ancestors = this.ancestors.slice(0,index);
            } else if (index == 0){
                // if the first element was clicked
                this.ancestors.length = 0;
            } else {
                this.ancestors.push(group);
            }
        }
        this._ancestors.next(this.ancestors);
    }

    // get the groups with ID from the server and 
    // Set the currently selected group
    setGroup(groupId): void{
        // Get the group through API
        this.updateAncestors(this.group,groupId);
        let req = this.httpClient.get(this.host + 'groups/' + groupId);
        req.subscribe(
            data => {
                // Set this.group to the result if successfull
                this.activateGroup(data);
            }, err => {
                //console.log(err);
            }
        ); 
    }

    // Get the groups for current selected group.
    getGroups(query): Observable<any>{
        let destination = this.host + 'groups?' + query;
        return this.httpClient.get(destination)
            .map(res => res);
    }

    getGroup(groupId): Observable<any>{
        return this.httpClient.get(this.host + 'groups/' + groupId)
            .map(res => res);
    }

    deleteGroup(groupId): Observable<any>{
        return this.httpClient.delete(this.host + 'groups/' + groupId)
            .map(res => res);
    }

    addGroup(type,body): Observable<any>{
        return this.httpClient.post(this.host + type,body)
            .map(res => res);
    }

    addCreditCard(groupId,body): Observable<any>{
        return this.httpClient.post(this.host + 'groups/' + groupId + '/creditcards',body)
            .map(res=>res);
    } 

    getCreditCards(groupId): Observable<any>{
        return this.httpClient.get(this.host + 'groups/' + groupId + '/creditcards')
            .map(res=>res);
    }

    getGroupActions(workaround): Observable<any> {
        let actionBar = [
            {
                "icon" : "nb-plus",
                "contextMenu" : [
                    { 
                        "title": 'Buy Devices',
                        "queryParams": 
                             {"action": "addSim"}
                        
                    },
                    { 
                        "title": 'Add Device Group',
                        "queryParams": 
                            {"action": "addSimGroup"}
                        
                    },
                    { 
                        "title": 'Add Customer',
                        "queryParams": 
                            {"action": "addCustomer"}
                        
                    },{ 
                        "title": 'Add Reseller',
                        "queryParams": 
                            {"action": "addReseller"}
                        
                    }
                ],
                "menuTag" : workaround + ".actionBar.add"
            },
            {
                "icon" : "nb-gear",
                "contextMenu" : [
                //    { "title": 'Configure Group' },
                    { 
                        "title": 'Group Details',
                        "queryParams": {
                            "action": "groupDetails"
                        }
                    },
                //    { "title": 'Another thing'},
                //    { "title": 'Enhancers' },
                //    { "title": 'Limitters' },
                ],
                "menuTag" : workaround + ".actionBar.gear",
                "disabled": true
            },
        ];
        let cardActions = [
            {
                "title": 'Select Group',
                "queryParams": {
                    "action": "selectGroup"
                }
            },{
                "title": 'Move Group',
                "queryParams": {
                    "action": "moveGroup"
                }
            },
            {
                "title": 'Group Details',
                "queryParams": {
                    "action": "subGroupDetails"
                }
            },
            {
                "title": 'Delete Group',
                "queryParams": {
                    "action": "deleteGroup"
                }
            }
        ]
        let actions = {
            "actionBar": actionBar,
            "cardActions": cardActions
        }
        return Observable.of(actions);

    }



}