//import { AppConfig } from './app-config';
//export { AppConfig } from './app-config';


import { InjectionToken } from '@angular/core';

export interface AppConfig {
    baseEndpoint: string;
    title: string;
  }

export const APP_CONFIG = new InjectionToken<AppConfig>('app.config');

export const GUI_CONFIG: AppConfig = {
  baseEndpoint: 'https://isimoapi.b-things.com:3000/v1/',
  //baseEndpoint: 'https://localhost:3000/v1/',
  //baseEndpoint: 'http://localhost:9000/v1/',
  title: 'Dependency '
};